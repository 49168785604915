import React, { useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import DateDecider from "./projects/DateDecider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SimplyBudget from "./projects/SimplyBudget";
import LukeWeathington from "./projects/LukeWeathington";
import Button from "@material-ui/core/Button";
import { faMicrochip, faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import JackArthur from "./projects/JackArthur";
import VideoGameAlliance from "./projects/VideoGameAlliance";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    position: "absolute",
    overflow: "auto",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: "800px",
    margin: "auto",
  },
}));

const Projects = () => {
  const classes = useStyles();
  const [openJackArthur, setOpenJackArthur] = React.useState(false);
  const [openSimplyBudget, setOpenSimplyBudget] = React.useState(false);
  const [openDateDecider, setOpenDateDecider] = React.useState(false);
  const [openVideoGameAlliance, setOpenVideoGameAlliance] =
    React.useState(false);
  const [openLukeWeathington, setOpenLukeWeathington] = React.useState(false);

  const handleOpenJackArthur = () => {
    setOpenJackArthur(true);
  };
  const handleOpenVideoGameAlliance = () => {
    setOpenVideoGameAlliance(true);
  };
  // const handleOpenSimplyBudget = () => {
  //   setOpenSimplyBudget(true);
  // };
  const handleOpenLukeWeathington = () => {
    setOpenLukeWeathington(true);
  };
  // const handleOpenDateDecider = () => {
  //   setOpenDateDecider(true);
  // };

  const handleClose = () => {
    setOpenJackArthur(false);
    setOpenVideoGameAlliance(false);
    setOpenSimplyBudget(false);
    setOpenDateDecider(false);
    setOpenLukeWeathington(false);
  };

  const isMobile = useMemo(() => window.innerWidth < 768, []);

  return (
    <Container fluid className='text-center'>
      <Row>
        {!isMobile ? (
          <>
            <Col className='p-0 col-12 col-md-4'>
              <VideoGameAlliance />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenVideoGameAlliance}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>

            <Col className='p-0 col-12 col-md-4'>
              <JackArthur />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenJackArthur}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>
            <Col className='p-0 col-12 col-md-4'>
              <LukeWeathington />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenLukeWeathington}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>
          </>
        ) : (
          <>
            <Col className='p-0 col-12 col-md-4'>
              <VideoGameAlliance />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenVideoGameAlliance}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>
            <Col className='p-0 col-12 col-md-4'>
              <JackArthur />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenJackArthur}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>
            <Col className='p-0 col-12 col-md-4'>
              <LukeWeathington />
              <Row className=' d-flex justify-content-center'>
                <Button
                  variant='contained'
                  color='primary'
                  className='m-2'
                  onClick={handleOpenLukeWeathington}
                >
                  <b>More Info</b>
                </Button>
              </Row>
            </Col>
          </>
        )}
      </Row>
      <Row>
        <Col>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={openJackArthur}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.paper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <h4>
                    Concept{" "}
                    <FontAwesomeIcon icon={faLightbulb} className='mr-2 p-0' />
                  </h4>
                  <h6 className='text-secondary p-1 btn' onClick={handleClose}>
                    <b>←Back</b>
                  </h6>
                </div>
                <hr />
                <p>
                  <b>Jack Arthur</b> is a social media platform for sharing,
                  discussing, and selling artwork. I was the sole developer of
                  the front-end and also assisted designing the UI/UX, and I
                  worked in tandem with the back-end developer to create the
                  application.
                </p>
              </div>
              <div className='video-responsive'>
                <iframe
                  width='420'
                  height='315'
                  src='https://www.youtube.com/embed/RpnUYlBPVx8'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </div>
              <br />
              <div>
                <h4 className='text-center'>
                  Tech Stack{" "}
                  <FontAwesomeIcon icon={faMicrochip} className='text-dark' />
                </h4>
                <hr />
                <Row className='d-flex justify-content-center'>
                  <div className='mr-4'>
                    <h6>Frontend</h6>
                    <ul className=''>
                      <li>
                        <p className='m-1'>
                          <b>React</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Redux</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>CSS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>HTML5</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Bootstrap</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Material-UI</b>
                        </p>
                      </li>
                      {/* <li>
                        <p className='m-1'>
                          <b>jQuery</b>
                        </p>
                      </li> */}
                    </ul>
                  </div>
                  <div className='ml-4'>
                    <h6>Backend</h6>
                    <ul>
                      <li>
                        <p className='m-1'>
                          <b>Go</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>gRPC</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>PostgreSQL</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        </Col>
        <Col>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={openSimplyBudget}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.paper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <h4>
                    Concept{" "}
                    <FontAwesomeIcon icon={faLightbulb} className='mr-2 p-0' />
                  </h4>
                  <h6 className='text-secondary p-1 btn' onClick={handleClose}>
                    <b>←Back</b>
                  </h6>
                </div>
                <hr />
                <p>
                  <b>Simply Budget</b> is a minimal and user-driven budgeting
                  app that prioritizes not over-complicating the process of
                  making and keeping a budget.
                </p>
              </div>
              <div className='video-responsive'>
                <iframe
                  width='420'
                  height='315'
                  src='https://www.youtube.com/embed/cRbwRi5D52k'
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                />
              </div>
              <br />
              <div>
                <h4 className='text-center'>
                  Tech Stack{" "}
                  <FontAwesomeIcon icon={faMicrochip} className='text-dark' />
                </h4>
                <hr />
                <Row className='d-flex justify-content-center'>
                  <div className='mr-4'>
                    <h6>Frontend</h6>
                    <ul className=''>
                      <li>
                        <p className='m-1'>
                          <b>React</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Redux</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>CSS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>HTML5</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Bootstrap</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Material-UI</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>jQuery</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='ml-4'>
                    <h6>Backend</h6>
                    <ul>
                      <li>
                        <p className='m-1'>
                          <b>Node.js</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Express</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>PostgreSQL</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Sequelize</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>BCrypt</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        </Col>
        <Col>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={openDateDecider}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.paper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <h4>
                    Concept{" "}
                    <FontAwesomeIcon icon={faLightbulb} className='mr-2 p-0' />
                  </h4>
                  <h6 className='text-secondary p-1 btn' onClick={handleClose}>
                    <b>←Back</b>
                  </h6>
                </div>
                <hr />
                <p>
                  <b>Date Decider</b> is a web app that helps indecisive couples
                  decide their plans for date night. I worked as part of a
                  three-person team, and I was solely responsible for the main
                  'date-deciding' application. I challenged myself with this
                  project to mimic a single page application without using a
                  framework like React or Vue.
                </p>
              </div>
              {/* <div className="video-responsive">
                <iframe
                  width="420"
                  height="315"
                  src="https://www.youtube.com/embed/cRbwRi5D52k"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div> */}
              <br />
              <div>
                <h4 className='text-center'>
                  Tech Stack{" "}
                  <FontAwesomeIcon icon={faMicrochip} className='text-dark' />
                </h4>
                <hr />
                <Row className='d-flex justify-content-center'>
                  <div className='mr-4'>
                    <h6>Frontend</h6>
                    <ul className=''>
                      <li>
                        <p className='m-1'>
                          <b>React</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>CSS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>HTML</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Bootstrap</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Material-UI</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>jQuery</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='ml-4'>
                    <h6>Backend</h6>
                    <ul>
                      <li>
                        <p className='m-1'>
                          <b>Node.js</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Express</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>PostgreSQL</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Sequelize</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>BCrypt</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        </Col>
        <Col>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={openVideoGameAlliance}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.paper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <h4>
                    Concept{" "}
                    <FontAwesomeIcon icon={faLightbulb} className='mr-2 p-0' />
                  </h4>
                  <h6 className='text-secondary p-1 btn' onClick={handleClose}>
                    <b>←Back</b>
                  </h6>
                </div>
                <hr />
                <p>
                  <b>Video Game Alliance</b> is a website for a local band in
                  Atlanta, GA that performs live video game music. I was the
                  sole developer of the website and worked closely with the band
                  to create a site that matched their aesthetic.
                </p>
              </div>
              {/* <div className="video-responsive">
                <iframe
                  width="420"
                  height="315"
                  src="https://www.youtube.com/embed/cRbwRi5D52k"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div> */}
              <br />
              <div>
                <h4 className='text-center'>
                  Tech Stack{" "}
                  <FontAwesomeIcon icon={faMicrochip} className='text-dark' />
                </h4>
                <hr />
                <Row className='d-flex justify-content-center'>
                  <div className='mr-4'>
                    <ul className=''>
                      <li>
                        <p className='m-1'>
                          <b>React</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>AWS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>React Query</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>CSS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>HTML</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Material-UI</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        </Col>
        <Col>
          <Modal
            aria-labelledby='spring-modal-title'
            aria-describedby='spring-modal-description'
            className={classes.modal}
            open={openLukeWeathington}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <div className={classes.paper}>
              <div>
                <div className='d-flex justify-content-between'>
                  <h4>
                    Concept{" "}
                    <FontAwesomeIcon icon={faLightbulb} className='mr-2 p-0' />
                  </h4>
                  <h6 className='text-secondary p-1 btn' onClick={handleClose}>
                    <b>←Back</b>
                  </h6>
                </div>
                <hr />
                <p>
                  <b>Luke Weathington</b> is an Atlanta-based saxophonist. I was
                  hired to create his portfolio website that is used for booking
                  gigs, music lessons, and general promotion.
                </p>
              </div>
              {/* <div className="video-responsive">
                <iframe
                  width="420"
                  height="315"
                  src="https://www.youtube.com/embed/cRbwRi5D52k"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div> */}
              <br />
              <div>
                <h4 className='text-center'>
                  Tech Stack{" "}
                  <FontAwesomeIcon icon={faMicrochip} className='text-dark' />
                </h4>
                <hr />
                <Row className='d-flex justify-content-center'>
                  <div className='mr-4'>
                    <ul className=''>
                      <li>
                        <p className='m-1'>
                          <b>React</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>AWS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>React Query</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>CSS</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>HTML</b>
                        </p>
                      </li>
                      <li>
                        <p className='m-1'>
                          <b>Material-UI</b>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Row>
              </div>
            </div>
          </Modal>
        </Col>
      </Row>
    </Container>
  );

  {
    /* <Container>
          <Card
            style={{
              backgroundColor: "rgb(20, 33, 61, .5)",
              marginBottom: "200px",
            }}
          >
            <Card.Header style={{ backgroundColor: "#FCA311" }}>
              <Nav variant="tabs" defaultActiveKey="#simply-budget">
                <Col className="col-12 col-md-4 p-0 m-0">
                  <Nav.Item>
                    <Nav.Link
                      className="ml-4 mr-4"
                      href="#fakeazon"
                      default
                      onClick={this.handleFakeazonClick}
                    >
                      <h5>Fake-A-Zon</h5>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
                <Col className="col-12 col-md-4 m-0 p-0">
                  <Nav.Item>
                    <Nav.Link
                      className="ml-4 mr-4"
                      href="#simply-budget"
                      onClick={this.handleSimplyBudgetClick}
                    >
                      <h5>Simply Budget</h5>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
                <Col className="col-12 col-md-4 m-0 p-0">
                  <Nav.Item>
                    <Nav.Link
                      className="ml-4 mr-4"
                      href="#date-decider"
                      onClick={this.handleDateDeciderClick}
                    >
                      <h5>Date Decider</h5>
                    </Nav.Link>
                  </Nav.Item>
                </Col>
              </Nav>
            </Card.Header>
            <Card.Body style={{ minHeight: "485px" }} className="text-dark">
              {this.state.showPlaceholderComponent ? <SimplyBudget /> : null}
              {this.state.showFakeazonComponent ? <Fakeazon /> : null}
              {this.state.showSimplyBudgetComponent ? <SimplyBudget /> : null}
              {this.state.showDateDeciderComponent ? <DateDecider /> : null}
            </Card.Body>
          </Card>
        </Container> */
  }
};

export default Projects;
