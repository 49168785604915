import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Headshot from "./img/headshot.gif";
import Button from "@material-ui/core/Button";
import { Fade } from "react-awesome-reveal";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { useAnimationCheck } from "./hooks/useAnimationCheck";

const About = () => {
  const { width } = useWindowDimensions();
  const animate = useAnimationCheck();

  return (
    <Container fluid>
      <Row>
        <Col xl='2' lg='2' md='0' sm='0' xs='0' />
        <Col
          className='d-flex justify-content-center p-3'
          xl='3'
          lg='3'
          md='12'
          sm='12'
          xs='12'
        >
          {animate ? (
            <Fade duration={3000} triggerOnce>
              <img
                src={Headshot}
                style={{ width: "50vw", maxWidth: "300px" }}
                className='d-flex align-self-center mt-4'
                alt='headshot'
              />
            </Fade>
          ) : (
            <img
              src={Headshot}
              style={{ width: "50vw", maxWidth: "300px" }}
              className='d-flex align-self-center mt-4'
              alt='headshot'
            />
          )}
        </Col>

        <Col
          className='p-3 w-100 '
          style={{ marginLeft: width > 992 ? "30px" : "0px" }}
          xl='5'
          lg='5'
          md='12'
          sm='12'
          xs='12'
        >
          {animate ? (
            <Fade direction='up' duration={2000} delay={500} triggerOnce>
              <h2 style={{ fontSize: "clamp(25px, 3vw, 40px)" }}>About me</h2>
            </Fade>
          ) : (
            <h2 style={{ fontSize: "clamp(25px, 3vw, 40px)" }}>About me</h2>
          )}
          {animate ? (
            <Fade direction='up' delay={1000} duration={2500} triggerOnce>
              <p className='' style={{ fontSize: "clamp(15px,2vw, 18px)" }}>
                I'm a Full-Stack Developer with an eye for design and a passion
                for building fully-responsive and intuitive websites and
                applications. I'm all about implementing user-centric
                experiences, and I'm always eager to learn new tech and broaden
                my skill set.
                <br /> <br />
                As more of our lives take place on the web, I have discovered a
                love for building and designing enjoyable and engaging spaces
                for people online. The combination of design, technology,
                ingenuity and creativity is what initially drew me to the field
                of software and web development, and I aim to bring that
                marriage of ideas to life in each project I work on.
              </p>
            </Fade>
          ) : (
            <p className='' style={{ fontSize: "clamp(15px,2vw, 18px)" }}>
              I'm a Full-Stack Developer with an eye for design and a passion
              for building fully-responsive and intuitive websites and
              applications. I'm all about implementing user-centric experiences,
              and I'm always eager to learn new tech and broaden my skill set.
              <br /> <br />
              As more of our lives take place on the web, I have discovered a
              love for building and designing enjoyable and engaging spaces for
              people online. The combination of design, technology, ingenuity
              and creativity is what initially drew me to the field of software
              and web development, and I aim to bring that marriage of ideas to
              life in each project I work on.
            </p>
          )}
        </Col>

        <Col xl='2' lg='2' md='0' sm='0' xs='0' />
      </Row>
      <Row className='d-flex justify-content-center'>
        <a href='Zac-Evans-Resume.pdf' target='_blank'>
          {animate ? (
            <Fade direction='down' delay={2500} duration={2000} triggerOnce>
              <Button
                variant='outlined'
                // color='primary'
                style={{
                  width: "200px",
                  height: "80px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "lightblue",
                  border: "solid 1px lightblue",
                }}
                className='m-4 font-wieght-bold'
              >
                View Resume
              </Button>
            </Fade>
          ) : (
            <Button
              variant='outlined'
              // color='primary'
              style={{
                width: "200px",
                height: "80px",
                fontSize: "20px",
                fontWeight: "bold",
                color: "lightblue",
                border: "solid 1px lightblue",
              }}
              className='m-4 font-wieght-bold'
            >
              View Resume
            </Button>
          )}
        </a>
        {/* <a href='/projects'>
          {animate ? (
            <Fade direction='down' delay={3500} duration={2000} triggerOnce>
              <Button
                variant='outlined'
                style={{
                  width: '200px',
                  height: '80px',
                  fontSize: '20px',
                  fontWeight: 'bold',
                  color: 'lightblue',
                  border: 'solid 1px lightblue',
                }}
                className='m-4'
              >
                See Projects
              </Button>
            </Fade>
          ) : (
            <Button
              variant='outlined'
              style={{
                width: '200px',
                height: '80px',
                fontSize: '20px',
                fontWeight: 'bold',
                color: 'lightblue',
                border: 'solid 1px lightblue',
              }}
              className='m-4'
            >
              See Projects
            </Button>
          )}
        </a> */}
      </Row>
    </Container>
  );
};

export default About;
